@import "property.scss";
@import "mixin.scss";
@import "colors.scss";

.Headline {
  @extend .__Block;
  &--page {
    font-size: 1.3rem;
    position: relative;
    padding: 2px 0 2px 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    @include res(md) {
      font-size: 26px;
      // padding: 5px 0 5px 25px;
      padding: 5px 0 5px 0;
    }
  }
  // &--page:before {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 5px;
  //   height: 100%;
  //   display: block;
  //   background: $MAIN_THEME_COLOR;
  //   content: "";
  // }
  &--section {
    font-size: 1.1rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    @include res(md) {
      font-size: 1.4rem;
    }
  }
  &--section-sub {
    font-size: 1rem;
    font-weight: bold;
  }
}
