$MAIN_THEME_COLOR: #1e3c4f;
$MAIN_THEME_COLOR_SECONDARY: #446c8f;
$MAIN_THEME_COLOR_PRIMARY: #5a90be;
$MAIN_THEME_COLOR_TERTIARY: #a7c8e5;
$MAIN_THEME_COLOR_BG: #d5e8f7;

$BACKGROUND_COLOR: #f4f5f6;
$ANNOTATION_COLOR: #be5a68;
$SUCCESS_COLOR: #5abeb0;
$ACCENT_COLOR: #ffc187;

$LINK_TEXT_COLOR: #1e3c4f;
$LINK_TEXT_COLOR_HOVER: #5a90be;
