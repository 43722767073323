@import "colors.scss";
@import "constants.scss";
@import "property.scss";
@import "mixin.scss";

.__list {
  background: #fff;
  padding: ($HEADER_HEIGHT) + px 0 20px;
  width: 220px;
  height: 100vh;
  border-right: 1px solid rgba(29, 71, 99, 0.2);
  display: block;
  overflow-y: auto;
}

.Sidebar {
  @extend .__Block;
  position: absolute;
  height: 100vh;
  @include res(md) {
    position: relative;
  }
  &__list {
    @extend .__list;
  }
  &__list--folded {
    @extend .__list;
    display: none;
  }
  &__row {
    padding: 12px 0 12px 54px;
    font-size: 14px;
    position: relative;
    color: #222;
    cursor: pointer;
    transition: all 0.2s ease;
    letter-spacing: -0.5px;
    svg {
      color: #777;
    }
    &:hover {
      color: #769dc2;
      background: #e9f7ff;
      svg {
        color: #3793e8;
      }
    }
  }
  &__row--current {
    color: #769dc2;
    background: $MAIN_THEME_COLOR_BG;
    svg {
      color: #3793e8;
    }
  }
  &__row--logout {
    &:before {
      content: none;
    }
  }
  &__row--with-company-choices {
    border-left: 8px solid $MAIN_THEME_COLOR_SECONDARY;
    padding-left: 46px;
  }
  &__row-icon {
    @extend .__Icon-wrapper;
    position: absolute;
    top: 50%;
    left: 22px;
  }
  &__sub-row {
    padding: 6px 0 6px 44px;
    font-size: 0.8rem;
    position: relative;
    color: #777;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  &__sub-row--current {
    color: #0d6efd;
    background: #e0e7f1;
  }
  &__company-switcher {
    display: block;
    background: $MAIN_THEME_COLOR_SECONDARY;
    padding: 5px 0 5px 10px;
    color: #fff;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      opacity: 0.8;
      color: #fff;
    }
  }
  @include print {
    display: none;
  }
}
