@import "colors.scss";
@import "mixin.scss";

.Mentions {
  margin: 0;
  padding: 0;
  color: #60626b;
  &__highlighter {
    padding: 4px 11px;
    line-height: 32px;
    border: 1px solid transparent;
    height: auto !important;
  }
  &__input {
    padding: 4px 11px;
    line-height: 32px;
    outline: 0;
    border: 1px solid #dee0e8;
    border-radius: 0.375rem;
  }
  &__mention {
    position: relative;
    z-index: 1;
    color: #0d6efd;
    text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white;
    pointer-events: none;
    display: inline-block;
  }
  &__suggestions__list {
    width: 140px;
    line-height: 20px;
    color: #60626b;
    font-size: 14px;
    border: 1px solid #e8eaf2;
    box-shadow: 0px 2px 8px rgba(61, 67, 102, 0.148055);
    border-radius: 2px;
    background: #f4f6fc;
  }
  &__suggestions__item {
    padding: 0 8px;
    &:hover {
      background-color: #e0e5fd;
      color: #507ff2;
    }
  }
  &__suggestions__item--focused {
    background-color: #e0e5fd;
    color: #507ff2;
  }
}

.index-list {
  padding: 0;
  margin: 0;
  width: 300px;
  border: 1px solid #e8eaf2;
  border-radius: 2px;
  li {
    padding: 0 20px;
    margin: 0;
    list-style: none;
    line-height: 30px;
    cursor: pointer;
    &:hover {
      background-color: #f4f6fc;
    }
  }
}
