@import "property.scss";
@import "mixin.scss";
@import "colors.scss";

.__list-item {
  display: flex;
  flex-direction: column;
  padding: 0 0 10px 0;
  margin: 0 0 10px 0;
  border-bottom: 1px solid #dee2e6;
  @include res(md) {
    padding: 8px 0;
    margin: 0;
    flex-direction: row;
  }
}
.Mapping-list {
  @extend .__Block;

  &__item {
    @extend .__list-item;
    &--featured {
      @extend .__list-item;
      background: #f7dfea;
    }
  }
  &__item-label {
    margin-bottom: 5px;
    @include res(md) {
      position: relative;
      margin: 0;
      padding-right: 10px;
      display: flex;
      align-items: center;
    }
    a {
      text-decoration: none;
    }
  }
  &__item-label-content {
    width: 100%;
  }
  &__item-text {
    @include res(md) {
      text-align: right;
    }
  }
  &__item-text-sub {
    font-size: 0.8rem;
    color: #777;
    @include res(md) {
      text-align: right;
    }
  }
  &__item-value {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    @include res(md) {
      padding-left: 10px;
    }
    a {
      text-decoration: none;
      cursor: pointer;
    }
  }
  &__item-thread {
    color: $MAIN_THEME_COLOR;
    @extend .__Link;
  }
}
