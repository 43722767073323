@import "colors.scss";
@import "property.scss";
@import "mixin.scss";

.__card-title {
  position: relative;
  font-size: 1rem;
  font-weight: bold;
  padding: 7px 10px;
  color: $MAIN_THEME_COLOR;
  background: $MAIN_THEME_COLOR_TERTIARY;
}
.__card-title-icon {
  position: absolute;
  right: 15px;
  transform: rotate(45deg);
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  border-style: solid;
  border-width: 7px;
  border-color: transparent $MAIN_THEME_COLOR $MAIN_THEME_COLOR transparent;
}

.Setting-card {
  @extend .__Block;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
  .__body {
    transition: 0.2s all ease-in-out;
    overflow: hidden;
    padding-bottom: 10px;
  }
  &__body {
    @extend .__body;
    &--folded {
      @extend .__body;
      height: 0;
      padding-bottom: 0;
    }
  }
  &__title {
    @extend .__card-title;
  }
  &__title--expanded {
    @extend .__card-title;
    cursor: pointer;
    &:after {
      @extend .__card-title-icon;
      top: 6px;
    }
  }
  &__title--folded {
    @extend .__card-title;
    cursor: pointer;
    &:after {
      @extend .__card-title-icon;
      top: 15px;
      transform: rotate(-135deg);
    }
  }
  &__description {
    font-size: 1rem;
    line-height: 1.3;
    color: #666;
    padding: 10px 10px 0 10px;
  }
  &__links {
    margin: 5px 0;
    padding: 0 10px;
  }
  &__link {
    list-style: none;
    position: relative;
    padding-left: 20px;
    a {
      text-decoration: none;
      color: $LINK_TEXT_COLOR;
    }
    &:before {
      position: absolute;
      top: 11px;
      left: 6px;
      transform: translate(0, -50%);
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: $MAIN_THEME_COLOR;
    }
  }
  &__link-description {
    color: #777;
    font-size: 0.85rem;
    padding-bottom: 3px;
  }
  &__check-lists {
    border: 1px solid #cfcfc4;
    background: linear-gradient(45deg, #fdfded, #fbfbfb);
    border-radius: 4px;
    padding: 6px 10px;
    margin: 10px;
    &:before {
      display: block;
      content: "【 進捗チェック 】";
      font-size: 1rem;
      color: #616155;
      margin-bottom: 3px;
    }
  }
  &__check-list {
    list-style: none;
    color: #939376;
  }
  &__flows {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-around;
    margin: 0 10px;
    padding-top: 10px;
    @include res(md) {
      flex-direction: row;
    }
  }
  &__flow {
    padding: 15px 10px;
    background: $MAIN_THEME_COLOR_BG;
    width: 100%;
    border-radius: 4px;
    border: 1px solid $MAIN_THEME_COLOR;
    margin: 5px 0;
    @include res(md) {
      padding: 20px 10px;
      width: 48%;
      margin: 0;
    }
  }
  &__flow-title {
    color: $MAIN_THEME_COLOR;
    line-height: 1;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 15px;
  }
  &__task {
    margin: 0 10px;
    position: relative;
    &:not(:last-child):before {
      position: absolute;
      top: 0;
      left: 0.5rem;
      width: 1px;
      height: 100%;
      content: "";
      background: $MAIN_THEME_COLOR;
    }
    &:not(:last-child) {
      padding-bottom: 20px;
    }
    &--editor {
      padding: 0.5rem;
      border-radius: 4px;
      background: #fff;
      border: 1px solid #ddd;
    }
    &-section + &-section {
      margin-top: 30px;
    }
  }

  &__task-title {
    font-size: 1rem;
    padding: 2px 0 2px 1.5rem;
    line-height: 1;
    position: relative;
    color: $MAIN_THEME_COLOR;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      background: $MAIN_THEME_COLOR;
      width: 1rem;
      height: 100%;
      border-radius: 3px;
    }
  }
  &__task-steps {
    padding: 5px 0 0 20px;
    &--editor {
      margin-top: 10px;
    }
  }
  &__task-step {
    &--editor {
      padding: 10px;
      &:not(:last-child) {
        padding-bottom: 15px;
      }
    }
  }
  &__task-links {
    margin-left: 15px;
  }
  [data-invalid="true"] {
    border: 1px solid $ANNOTATION_COLOR;
    border-radius: 5px;
  }
}
