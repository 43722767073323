@import "property.scss";
@import "mixin.scss";
@import "colors.scss";

.Tag-cloud {
  @extend .__Block;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 15px;
  .__item {
    @extend .__Link;
    display: inline-block;
    padding: 4px;
    line-height: 1;
    margin: 5px 5px 5px 0;
    cursor: pointer;
    border: 1px solid transparent;
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    color: #777;
    @extend .__item;
  }
  &__item--selected {
    @extend .__item;
    color: $LINK_TEXT_COLOR;
    border-radius: 3px;
    background: #dae9f3;
    border: 1px solid $LINK_TEXT_COLOR;
  }
}
