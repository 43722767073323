@import "property.scss";

.__Stepper__item {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  &:before {
    content: "";
    position: relative;
    z-index: 1;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #c0c0c0;
    margin: 0.5rem auto 0;
  }
  &:not(:last-child):after {
    content: "";
    position: relative;
    top: 1rem;
    left: 50%;
    height: 3px;
    background-color: #c0c0c0;
    order: -1;
  }
}
.Stepper {
  @extend .__Block;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &__item {
    @extend .__Stepper__item;
  }
  &__item--done {
    &:before {
      background-color: $MAIN_THEME_COLOR_SECONDARY;
    }
    &:not(:last-child):after {
      background-color: $MAIN_THEME_COLOR_SECONDARY;
    }
  }
  &__item--active {
    &:not(:last-child):after {
      background-color: #c0c0c0;
    }
  }
}
