@import "property.scss";
@import "colors.scss";
@import "mixin.scss";
@import "constants.scss";

.__notifications-balloon {
  position: absolute;
  top: ($HEADER_HEIGHT - 5) + px;
  right: 0;
  display: none;
  width: 300px;
  max-height: calc(100vh - 70px);
  border-radius: 4px;
  overflow: scroll;
  @include res(md) {
    width: 450px;
  }
}

.__myaccount-balloon {
  position: absolute;
  top: ($HEADER_HEIGHT - 5) + px;
  right: 5px;
  display: none;
  width: 300px;
  background: #fff;
  border-radius: 4px;
  overflow: visible;
}

.__threads-text {
  font-size: 0.8rem;
  padding: 0 10px 0 50px;
  color: #333;
  display: none;
  @include res(md) {
    display: block;
  }
  @include print {
    display: none;
  }
}

.Header {
  @extend .__Block;
  position: fixed;
  width: 100%;
  height: 50px;
  z-index: 8192;
  transition: transform 0.3s ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $MAIN_THEME_COLOR_SECONDARY;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
  &__col {
    display: flex;
    align-items: stretch;
    height: 100%;
  }
  &__logo-frame {
    padding-left: 10px;
    align-items: center;
  }
  &__logo {
    @extend .__Link;
    display: block;
    width: 37px;
    background: URL("apple-touch-icon.png");
    background-size: contain;
    text-indent: -9999px;
    @include res(sm) {
      width: 160px;
      background: URL("logo_header.png");
      background-repeat: no-repeat;
    }
  }
  &__threads {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease;
    width: 50px;
    text-decoration: none;
    @include res(md) {
      background: #fff;
      width: auto;
      &:hover {
        color: #769dc2;
        background: #e9f7ff;
      }
    }
  }
  &__threads-none {
    position: relative;
    display: flex;
    align-items: center;
    transition: all 0.2s ease;
    width: 50px;
    text-decoration: none;
    @include res(md) {
      background: #fff;
      width: auto;
    }
  }
  &__threads-icon {
    position: absolute;
    top: 54%;
    left: 10px;
    transform: translate(0, -42%);
    width: 30px;
    height: 30px;
    color: #dedede;
    @include res(md) {
      color: #3793e8;
      transform: translate(0, -50%);
    }
  }
  &__threads-text {
    @extend .__threads-text;
    &--active {
      @extend .__threads-text;
      color: $ANNOTATION_COLOR;
    }
  }
  &__notifications {
    position: relative;
    width: 50px;
  }
  &__notifications-toggler {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    transition: all 0.1s ease;
    cursor: pointer;
    &:hover {
      color: #e9f7ff;
    }
  }
  &__notifications-badge {
    position: absolute;
    top: 0;
    right: 0;
    width: 16px;
    height: 16px;
    line-height: 16px;
    background: $ANNOTATION_COLOR;
    text-align: center;
    border-radius: 50%;
    font-size: 0.7rem;
  }
  &__notifications-balloon {
    @extend .__notifications-balloon;
  }
  &__notifications-balloon--active {
    @extend .__notifications-balloon;
    display: block;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  }
  &__myaccount-balloon {
    @extend .__myaccount-balloon;
  }
  &__myaccount-balloon--active {
    @extend .__myaccount-balloon;
    display: block;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  }
  &__profile {
    display: flex;
    align-items: center;
    padding-right: 10px;
    @extend .__Link;
  }
  &__profile-row {
    height: 25px;
    line-height: 25px;
    font-size: 0.8rem;
    text-align: left;
    color: #fff;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &__profile-image-frame {
    position: relative;
    width: 50px;
    img {
      display: block;
      width: auto;
      height: 100%;
    }
  }
  &__profile-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #dedede;
  }
  &__profile-text {
    display: none;
    @include res(md) {
      display: block;
    }
  }
  &__nav-content {
    display: flex;
    align-items: center;
    height: 100%;
  }
  &__nav-links {
    margin-left: 10px;
    display: flex;
    height: 100%;
  }
  &__nav-link-item {
    background: #fff;
    color: #333;
    font-size: 13px;
    line-height: 1;
    cursor: pointer;
    padding: 15px 10px 10px;
    position: relative;
    transition: all 0.2s ease;
    &.--current {
      background: $MAIN_THEME_COLOR_BG;
      color: $MAIN_THEME_COLOR_SECONDARY;
      &:before {
        position: absolute;
        bottom: 5px;
        left: 50%;
        content: "";
        width: 70%;
        height: 5px;
        background: $MAIN_THEME_COLOR_SECONDARY;
        transform: translate(-50%, 0);
      }
    }
    &:not(.--current):hover {
      background: $MAIN_THEME_COLOR_BG;
      color: $MAIN_THEME_COLOR_SECONDARY;
    }
  }
  &__file {
    position: relative;
    display: flex;
    width: 50px;
    color: #fff;
  }
  &__file--open {
    background: #fff;
    color: #769dc2;
  }
  &__file-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.1s ease;
    cursor: pointer;
  }
  &__side-bar-toggler {
    position: relative;
    display: flex;
    width: 50px;
    color: #fff;
  }
  &__side-bar-toggler-icon {
    cursor: pointer;
    padding-left: 10px;
  }
  @include print {
    display: none;
  }
}
