@import "colors.scss";
@import "property.scss";
@import "mixin.scss";

.Setting-steps {
  @extend .__Block;
  display: flex;
  flex-flow: column;
  &__item {
    position: relative;
    line-height: 1;
    margin-top: 15px;
    padding: 3px 0;
    border-radius: 4px;
    width: 100%;
    text-align: center;
    &:before {
      position: absolute;
      top: -15px;
      left: 50%;
      transform: rotate(45deg);
      content: "";
      width: 10px;
      height: 10px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent $MAIN_THEME_COLOR $MAIN_THEME_COLOR transparent;
    }
    &:first-child {
      margin-top: 0;
      &:before {
        display: none;
      }
    }
    &[data-disabled="true"] {
      color: #aaa;
      &:before {
        border-color: transparent #aaa #aaa transparent;
      }
    }
    &[data-active="true"] {
      color: #0d6efd;
      background: #dae9f3;
    }
  }
}
