html {
  height: 100%;
}
body {
  height: 100%;
  background: $BACKGROUND_COLOR;
  font-family: Meiryo, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Noto Sans Japanese",
    "ヒラギノ角ゴ ProN W3", sans-serif;
  --bs-link-color: #446c8f;
  --bs-link-hover-color: #a7c8e5;
}
figure {
  margin-bottom: 0;
}
h1,
p h2,
h3,
h4,
h5 {
  margin: 0;
}
ul {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}

.react-datepicker__input-container {
  input {
    padding: 0.375rem 0.75rem;
    color: #6c757d;
    border: 1px solid #6c757d;
    border-radius: 0.25rem;
  }
}
body .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  margin-left: -100px;
}

@for $i from 1 through 3 {
  $zIndexBackdrop: #{1020 + (5 * $i)};
  $zIndexContent: #{1020 + (5 * $i) + 2};
  .modal-backdrop.show:nth-of-type(#{$i}) {
    z-index: $zIndexBackdrop;
  }
  div[role="dialog"][aria-modal="true"]:nth-of-type(#{$i}) {
    z-index: $zIndexContent;
  }
}

body .accordion.input button.accordion-button {
  --bs-accordion-btn-padding-y: 0.5rem;
  --bs-accordion-btn-padding-x: 0.75rem;
  --bs-accordion-btn-icon-width: 0.8rem;
  height: 2.33rem;
}

body .accordion {
  --bs-accordion-active-color: #000;
  --bs-accordion-active-bg: #a7c8e5;
  --bs-accordion-btn-focus-border-color: #5a90be;
}

body .card {
  --bs-card-cap-bg: #d5e8f7;
}

body .border-primary {
  --bs-border-opacity: 1;
  border-color: rgba($MAIN_THEME_COLOR_PRIMARY, var(--bs-border-opacity)) !important;
}

body .border-success {
  --bs-border-opacity: 1;
  border-color: rgba($SUCCESS_COLOR, var(--bs-border-opacity)) !important;
}

body .dropdown-menu {
  max-height: 250px;
  overflow: scroll;
}

// アラート
body .alert-success {
  --bs-alert-color: #2d5f58;
  --bs-alert-bg: #def2ef;
  --bs-alert-border-color: #c8ccd4;
}

body .alert-info {
  --bs-alert-color: #1e3c4f;
  --bs-alert-bg: #d5e8f7;
  --bs-alert-border-color: #c8ccd4;
}

body .alert-warning {
  --bs-alert-color: #7e6144;
  --bs-alert-bg: #fef3e7;
  --bs-alert-border-color: #c8ccd4;
}

body .alert-danger {
  --bs-alert-color: #5f2d34;
  --bs-alert-bg: #f2dee1;
  --bs-alert-border-color: #c8ccd4;
}

// トグル
body .form-check-input:checked {
  background-color: $MAIN_THEME_COLOR_SECONDARY;
  border-color: $MAIN_THEME_COLOR_SECONDARY;
}

// ボタン
body .btn-outline-primary {
  --bs-btn-color: #1e3c4f;
  --bs-btn-border-color: #1e3c4f;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1e3c4f;
  --bs-btn-hover-border-color: #1e3c4f;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1e3c4f;
  --bs-btn-active-border-color: #1e3c4f;
  --bs-btn-disabled-color: #1e3c4f;
  --bs-btn-disabled-border-color: #1e3c4f;
}

body .btn-outline-secondary {
  --bs-btn-color: #446c8f;
  --bs-btn-border-color: #446c8f;
  --bs-btn-hover-bg: #446c8f;
  --bs-btn-hover-border-color: #446c8f;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #446c8f;
  --bs-btn-active-border-color: #446c8f;
  --bs-btn-disabled-color: #446c8f;
  --bs-btn-disabled-border-color: #446c8f;
}

body .btn-primary {
  --bs-btn-bg: #5a90be;
  --bs-btn-border-color: #5a90be;
  --bs-btn-hover-bg: #487398;
  --bs-btn-hover-border-color: #487398;
  --bs-btn-active-bg: #fff;
  --bs-btn-active-border-color: #1e3c4f;
  --bs-btn-disabled-bg: #9ba1ae;
  --bs-btn-disabled-border-color: #9ba1ae;
}

body .btn-danger {
  --bs-btn-bg: #be5a68;
  --bs-btn-border-color: #be5a68;
  --bs-btn-hover-bg: #be5a68;
  --bs-btn-hover-border-color: #be5a68;
  --bs-btn-active-bg: #be5a68;
  --bs-btn-active-border-color: #be5a68;
  --bs-btn-disabled-bg: #be5a68;
  --bs-btn-disabled-border-color: #be5a68;
}

body .btn-outline-danger {
  --bs-btn-color: #be5a68;
  --bs-btn-border-color: #be5a68;
  --bs-btn-hover-bg: #be5a68;
  --bs-btn-hover-border-color: #be5a68;
  --bs-btn-active-bg: #be5a68;
  --bs-btn-active-border-color: #be5a68;
  --bs-btn-disabled-color: #be5a68;
  --bs-btn-disabled-border-color: #be5a68;
}

// バッヂ
body .bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba($MAIN_THEME_COLOR_PRIMARY, var(--bs-bg-opacity)) !important;
}

body .bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba($SUCCESS_COLOR, var(--bs-bg-opacity)) !important;
}

body .bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba($ANNOTATION_COLOR, var(--bs-bg-opacity)) !important;
}

.react-datepicker__tab-loop > .react-datepicker-popper {
  z-index: 7 !important;
}
