@import "colors.scss";
@import "mixin.scss";

.--bg-white-transparent {
  background: rgba(255, 255, 255, 0.8);
}
.--block-link {
  display: block;
  text-decoration: none;
  color: inherit;
}
.--inline-link {
  display: inline;
}
.--cursor-pointer {
  cursor: pointer;
}
.--hidden {
  display: none;
}
.--bold {
  font-weight: bold;
}
.--font-xs {
  font-size: 0.6rem;
}
.--font-s {
  font-size: 0.8rem;
}
.--font-m {
  font-size: 1.2rem;
}
.--font-l {
  font-size: 1.5rem;
}
.--print-none {
  @include print {
    display: none;
  }
}
.--bullet {
  position: relative;
  padding-left: 1em;
  &:before {
    position: absolute;
    top: 8px;
    left: 0;
    content: "";
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: $MAIN_THEME_COLOR;
  }
}
.--flex {
  display: flex;
}
.--wrap {
  flex-wrap: wrap;
}
.--align-items-center {
  align-items: center;
}
.--space-between {
  justify-content: space-between;
}
.--text-align-center {
  text-align: center;
}

.--required-label {
  &:after {
    content: "*";
    padding-left: 4px;
    font-size: 13px;
    color: $ANNOTATION_COLOR;
  }
}

.--pre {
  white-space: pre;
}
.--pre-wrap {
  white-space: pre-wrap;
}

.--no-wrap {
  white-space: nowrap;
}

.--max-width-100 {
  max-width: 100%;
}

@for $i from 1 through 50 {
  .--w-#{$i} {
    width: $i * 10px !important;
  }
}
.--text-main-color {
  color: $MAIN_THEME_COLOR !important;
}
.--text-light-color {
  color: $MAIN_THEME_COLOR_SECONDARY !important;
}
.--text-annotation {
  color: $ANNOTATION_COLOR !important;
}
.--text-link {
  text-decoration: none !important;
  color: $LINK_TEXT_COLOR !important;
}

@for $i from 1 through 4 {
  .--px-#{$i} {
    padding: 0 $i * 0.25rem !important;
  }
}
@for $i from 1 through 4 {
  .--py-#{$i} {
    padding: $i * 0.25rem 0 !important;
  }
}

.--word-break-all {
  word-break: break-all;
}

.--ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}
.--value-row {
  &:hover {
    background-color: $MAIN_THEME_COLOR_TERTIARY;
  }
}
.--overflow-auto {
  overflow: auto;
  max-height: 60vh;
}

.--icon-square {
  display: inline-block;
  width: 22px;
  height: 22px;
  border-radius: 4px;
  margin-right: 6px;
  &.--update {
    background: #aa6acf;
  }
  &.--update-sub {
    background: #d8b7ea;
  }
  &.--delete {
    background: #e70c0c;
  }
  &.--register {
    background: #46c28f;
  }
}

.--attached-image {
  max-width: 140px;
  width: 100%;
  padding: 5px;
  background: #f0f0f0;
  border-radius: 5px;
  img {
    width: 100%;
    height: auto;
  }
  &.--medium {
    max-width: 300px;
  }
  &.--large {
    max-width: 800px;
  }
}

.--color-white {
  color: #fff;
}
