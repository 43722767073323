.MailSettingConditions-card {
  border: 1px solid #cedce9;
  background: #f3f6f9;
  &__name {
    color: #888;
  }
  &__account-item {
    list-style: initial;
  }
  &__account-item-delete {
    transform: scale(0.9);
  }
}
