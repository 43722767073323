@import "property.scss";
@import "mixin.scss";
@import "colors.scss";

.Thread-box {
  @extend .__Block;
  background: $BACKGROUND_COLOR;
  overflow: scroll;
  max-height: 70vh;
  padding: 20px 10px 80px;
}
