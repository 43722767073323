@import "colors.scss";
@import "property.scss";
@import "mixin.scss";

.__card-button {
  background: #fff;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  padding: 7px 10px 7px 25px;
  margin: 5px 0;
  position: relative;
  font-size: 1rem;
  color: $LINK_TEXT_COLOR;
  transition: all 0.2s ease;
  text-decoration: none;
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
  &:hover {
    opacity: 0.7;
  }
  @include res(md) {
    width: 48%;
  }
  &:before {
    position: absolute;
    top: 14px;
    left: 7px;
    content: "";
    width: 10px;
    height: 2px;
    transform: rotate(45deg);
    background: #777;
    transition: all 0.2s ease;
  }
  &:after {
    position: absolute;
    top: 20px;
    left: 7px;
    content: "";
    width: 10px;
    height: 2px;
    transform: rotate(-45deg);
    background: #777;
    transition: all 0.2s ease;
  }
  &:hover:before,
  &:hover:after {
    background: #0d6efd;
  }
}

.Dashboard-card {
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  background: #fff;
  @include res(md) {
    width: 49%;
    padding: 20px;
  }
  &__title {
    font-size: 1.1rem;
    font-weight: bold;
    color: #777;
    margin-bottom: 10px;
    @include res(md) {
      font-weight: normal;
      font-size: 1.5rem;
    }
  }
  &__buttons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    @include res(md) {
      flex-direction: row;
    }
  }
  &__button {
    @extend .__card-button;
  }

  &__button--wide {
    @extend .__card-button;
    width: 100%;
  }

  &__button-description {
    font-size: 0.8rem;
    font-weight: normal;
    margin-top: 4px;
  }
  &__row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    @include res(md) {
      flex-direction: row;
    }
  }
}
