@import "property.scss";
@import "colors.scss";
@import "mixin.scss";

.__Pagenation-page {
  width: 24px;
  padding: 2px 0;
  margin: 0 2px;
  color: $LINK_TEXT_COLOR;
  text-align: center;
  border-bottom: 1px solid transparent;
  cursor: pointer;
}
.__Pagenation-button {
  color: $LINK_TEXT_COLOR;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
}

.Pagenation {
  @extend .__Block;
  display: flex;
  align-items: center;
  &__button {
    @extend .__Pagenation-button;
  }
  &__button--disabled {
    @extend .__Pagenation-button;
    color: #888;
  }
  &__pages {
    display: flex;
    align-items: center;
  }
  &__page {
    @extend .__Pagenation-page;
  }
  &__page--current {
    @extend .__Pagenation-page;
    color: #888;
    border-bottom-color: #888;
    font-weight: bold;
    cursor: default;
  }
}
