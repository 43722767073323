@import "property.scss";
@import "mixin.scss";

.Layout {
  @extend .__Block;
  display: flex;
  position: relative;
  &__side {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1024;
    @include res(md) {
      position: sticky;
      z-index: 1024;
      height: 100vh;
    }
  }
  &__main {
    padding: 0 10px 15px;
    padding-top: $HEADER_HEIGHT + 15px;
    width: 100%;
    @include res(md) {
      padding-top: $HEADER_HEIGHT + 30px;
      z-index: 1;
      overflow: hidden;
      margin: 0 30px;
    }
  }
  &__full {
    padding: 0 10px 15px;
    padding-top: $HEADER_HEIGHT + 15px;
    width: 100%;
    min-height: 100vh;
    @include res(md) {
      padding-top: $HEADER_HEIGHT + 30px;
      position: relative;
      z-index: 1;
      overflow: hidden;
    }
  }
}
