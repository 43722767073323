@import "property.scss";
@import "colors.scss";
@import "mixin.scss";

.__MyAccount__item {
  word-break: break-all;
  line-height: 1.5;
}
.MyAccount {
  @extend .__Block;
  padding: 20px 15px 5px;
  border-radius: 4px;
  background: #fff;
  &__item {
    @extend .__MyAccount__item;
  }
  &__item-name {
    @extend .__MyAccount__item;
    margin-bottom: 4px;
    font-weight: 600;
  }
  &__item-email {
    @extend .__MyAccount__item;
    margin-bottom: 9px;
    font-size: 0.85em;
  }
  &__item-company {
    @extend .__MyAccount__item;
    margin-bottom: 10px;
  }
  &__item-textlink {
    @extend .__MyAccount__item;
    display: inline-block;
    font-size: 0.85em;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    &:hover {
      color: $LINK_TEXT_COLOR_HOVER;
    }
  }
  &__item-role-list {
    @extend .__MyAccount__item;
    padding: 15px 5px 0px;
    margin: 10px;
    border-top: 1px solid #ddd;
    position: relative;
  }
  &__item-role-current {
    @extend .__MyAccount__item;
    padding: 10px 5px 0px;
    position: relative;
  }
  &__item-role-choices {
    @extend .__MyAccount__item;
    padding: 10px 5px 0px;
    position: relative;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    &:hover {
      color: #0d6efd;
    }
  }
  &__item-tooltip {
    @extend .__MyAccount__item;
    padding: 10px 5px 0px;
    position: relative;
    z-index: 9999;
  }
  &__item-logout {
    @extend .__MyAccount__item;
    padding: 10px;
    margin: 10px;
    border-top: 1px solid #ddd;
    position: relative;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    &:hover {
      color: #0d6efd;
      background-color: #dae9f3;
    }
    &:before {
      position: absolute;
      top: 50%;
      right: 15px;
      width: 4px;
      height: 8px;
      display: inline-block;
      content: "";
      background: URL("icon_bread_arrow.png") no-repeat 0 0;
      transform: translateY(-50%);
    }
  }
}
