@import "property.scss";

.Covered-image {
  @extend .__Block;
  position: relative;
  overflow: hidden;
  &__img {
    position: absolute;
    z-index: -1;
    width: auto;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  &__img--blur {
    filter: blur(2px);
  }
}
