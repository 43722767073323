@import "colors.scss";
@import "property.scss";
@import "mixin.scss";

.Apply-form {
  @extend .__Block;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;

  .__row {
    margin: 5px 0;
  }
  @include res(md) {
    padding: 15px;
  }
  &__row {
    @extend .__row;
    display: flex;
    align-items: center;
  }
  &__row-key {
    width: 40%;
    padding: 0 5px 0 0;
    word-break: break-all;
    font-weight: bold;
  }
  &__row-value {
    width: 60%;
    padding: 0 0 0 5px;
  }
  &__row-line {
    width: 100%;
    height: 1px;
    margin: 10px 0;
    background: #777;
  }
  &__row-text {
    line-height: 1.5;
  }
}
