@import "property.scss";

.Overlay {
  @extend .__Block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1200;
  background: rgba(255, 255, 255, 0.8);
  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1201;
  }
}
