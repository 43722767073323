@import "property.scss";
@import "mixin.scss";
@import "colors.scss";

.Anchor-link {
  @extend .__Block;
  padding: 10px 15px;
  border: 1px solid $MAIN_THEME_COLOR_TERTIARY;
  border-radius: 5px;
  background: #f2f2f2;
  display: flex;
  flex-wrap: wrap;
  &__item {
    display: inline-block;
    margin-right: 3em;
    text-decoration: none;
    color: var(--bs-link-color);
    &:before {
      display: inline-block;
      content: "▼";
      padding-right: 1em;
    }
  }
  &__item--label {
    margin: 0 auto;
    display: inline-block;
    text-decoration: none;
    font-size: 1.1rem;
    color: var(--bs-link-color);
    padding-top: 1em;
  }
}
