@import "colors.scss";
@import "property.scss";

.__Badge {
  display: inline-block;
  padding: 1px 6px;
  border-radius: 3px;
  font-size: 14px;
  flex-shrink: 0;
}
.Badge {
  &--ok {
    @extend .__Badge;
    color: #fff;
    background-color: $SUCCESS_COLOR;
  }
  &--running {
    @extend .__Badge;
    color: $MAIN_THEME_COLOR_PRIMARY;
    background: #fff;
    border: 1px solid $MAIN_THEME_COLOR_PRIMARY;
  }
  &--waiting {
    @extend .__Badge;
    color: #777;
    background: #fff;
    border: 1px solid #777;
  }
  &--danger {
    @extend .__Badge;
    color: $ANNOTATION_COLOR;
    background: #fff;
    border: 1px solid $ANNOTATION_COLOR;
  }
  &--cancel {
    @extend .__Badge;
    color: #fff;
    background: #656565;
  }
  &--count {
    @extend .__Badge;
    background: $ANNOTATION_COLOR;
    color: #fff;
    border: none;
    border-radius: 50%;
    font-size: 12px;
    transform: translateY(-1px);
  }
}
