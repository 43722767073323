@import "colors.scss";
@import "property.scss";
@import "mixin.scss";

.News-card {
  padding: 10px 15px;
  border-radius: 4px;
  background: #fff;
  &__title {
    font-size: 1rem;
    font-weight: bold;
    color: #616155;
    margin-bottom: 8px;
  }
  &__item {
    padding-left: 1em;
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #616155;
    }
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  &__item-date {
    color: #888;
    margin-right: 8px;
    font-size: 0.8rem;
  }
  &__item-icon-new {
    color: #fff;
    background: #f22222;
    padding: 2px 5px;
    border-radius: 3px;
    line-height: 1;
    font-size: 0.8rem;
    margin: 0 4px;
  }
  &__item-label {
    font-size: 1rem;
    text-decoration: none;
    line-height: 1.1;
  }
}
