@import "colors.scss";
@import "mixin.scss";

.Profile-card {
  padding: 15px;
  border-radius: 5px;
  background: $BACKGROUND_COLOR;
  &__image {
    width: 100%;
    max-width: 140px;
    aspect-ratio: 1;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    position: relative;
    background: $BACKGROUND_COLOR;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      height: 100%;
    }
    &.--empty {
      background: #ddd;
      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1rem;
        color: #bbb;
        line-height: 1;
        content: "No Image";
      }
    }
  }
  &__data {
    display: flex;
    font-size: 0.8rem;
    & + & {
      margin-top: 8px;
    }
  }
  &__label {
    font-weight: bold;
    margin-right: 1em;
    word-break: keep-all;
  }
  &__blank-label {
    margin-right: 3em;
  }
}
