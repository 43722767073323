@import "colors.scss";
@import "mixin.scss";

$TREE_BACKGROUND: $BACKGROUND_COLOR;
.Tree {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background: $TREE_BACKGROUND;
  overflow-x: scroll;
  &__nodes {
    position: relative;
    overflow-y: hidden;
  }
  &__node {
    position: relative;
    &:before {
      position: absolute;
      top: 0;
      left: 8px;
      content: "";
      background: $MAIN_THEME_COLOR_SECONDARY;
      width: 1px;
      height: 100%;
      z-index: 32;
    }
    &:first-child:before {
      height: calc(100% - 15px);
      top: 15px;
    }
    &:last-child:before {
      height: 15px;
    }
    &:last-child:after {
      position: absolute;
      top: 15px;
      left: 8px;
      content: "";
      background: $TREE_BACKGROUND;
      width: 1px;
      height: calc(100% - 15px);
      z-index: 29;
    }
    &--open {
      &:last-child:after {
        background: $MAIN_THEME_COLOR_SECONDARY;
      }
    }
  }
  &__item {
    color: #333;
    padding-left: 25px;
    position: relative;
    display: inline-block;
    &:before {
      position: absolute;
      top: 12px;
      left: 0;
      transform: translate(5px, -50%);
      content: "";
      border-radius: 50%;
      width: 7px;
      height: 7px;
      background: $MAIN_THEME_COLOR_SECONDARY;
      z-index: 64;
    }
    &-toggler {
      cursor: pointer;
      position: absolute;
      top: 6px;
      left: 1px;
      width: 17px;
      height: 17px;
      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        width: 7px;
        height: 7px;
        background: $MAIN_THEME_COLOR_SECONDARY;
        z-index: 64;
        width: 100%;
        height: 100%;
        content: "";
        .Tree__item--open & {
          background: $ACCENT_COLOR;
        }
      }
      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: "+";
        font-size: 15px;
        color: #fff;
        font-weight: bold;
        text-align: center;
        z-index: 67;
        .Tree__item--open & {
          content: "-";
        }
      }
    }

    .Tree__node:not(.--root):first-child > & .Tree__item-inner {
      position: relative;
      &:before {
        position: absolute;
        top: 30px;
        left: -66px;
        width: 50px;
        height: 1px;
        content: "";
        background: $MAIN_THEME_COLOR_SECONDARY;
        z-index: 32;
      }
    }
  }

  &__children {
    padding-left: 50px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  &__node--open:last-child &__children:before {
    position: absolute;
    top: 31px;
    left: 8px;
    width: 1px;
    height: calc(100% - 31px);
    background: $TREE_BACKGROUND;
    content: "";
    z-index: 35;
  }
  &__box {
    padding: 0 10px 25px;
    position: relative;
    display: inline-block;
    min-width: 300px;
    max-width: 600px;
    &-title {
      background: #fff;
      padding: 7px 10px 7px;
      font-size: 0.8rem;
      line-height: 1;
      border-radius: 5px;
      &:not(:last-child) {
        border-radius: 5px 5px 0 0;
        border-bottom: 1px solid #ddd;
      }
    }
    &-content {
      padding: 10px;
      font-size: 0.8rem;
      border-radius: 0 0 5px 5px;
      background: #fff;
    }
  }
  &__portrait {
    width: 34px;
    height: 34px;
    margin: 1px 0;
    border-radius: 50%;
    flex-shrink: 0;
    overflow: hidden;
    position: relative;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      height: 100%;
    }
    &:empty:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      content: "";
      background: #777;
    }
  }
  &__position {
    width: 200px;
    color: #777;
    line-height: 34px;
  }
  &__members {
    min-width: 400px;
  }
  &__member-name {
    line-height: 34px;
    &:not(:first-child) {
      margin-left: 4px;
    }
  }
}
